import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography, TextField } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { emailValidator } from '../util/validator';
//import ForgotPassword from '../components/modals/ForgotPassword';
import { useSnackbar } from 'notistack';
import {signInApi} from '../services/authService';
import {login} from '../actions/auth';
import {connect} from 'react-redux'; 
import { consoleToLog, getBackUrl, getServiceName } from '../util/AppUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { getAuth, signInWithCustomToken } from "firebase/auth";
import { Link, redirect, useNavigate } from 'react-router-dom';
import {setLoading} from '../actions/loading';
import ForgotPasswordModal from '../components/modals/ForgotPasswordModal';
import {useIsMount} from '../components/useIsMount';
import {respectiveAppTitleAndSubtitle} from '../util/AppUtil';
import { fetchAccessToken } from '../util/AppUtil';
import { getQueryParams } from '../util/urlUtil';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100vw',
        height:'100vh',
        overflow:'auto'
    },
    onBoardingDesignStyle : {
        background: '#3D3D3D',
        height:'100vh',
        padding:'40px 48px'
    },
    logoStyle: {
        width: '220px',
        height: '49.18px',
        [theme.breakpoints.down(1400)] : {
            width: '190px',
            height: '40px',
        }
    },
    headingContainer: {
        marginTop:'70px'
    },
    headingTextStyle: {
        fontSize:'25px',
        fontWeight: '600',
        color:'#fff',
        lineHeight:'31px',
        [theme.breakpoints.down(1400)] : {
            fontSize:'21px'
        }
    },
    paragraphTextStyle: {
        marginTop:'16px',
        color:'#fff',
        fontSize:'13px',
    },
    imageContainer : {
        marginTop:'72px',
        [theme.breakpoints.down(1400)] : {
            marginTop:'52px'
        }
    },
    rightPanelStyle: {
        background:  theme.palette.primary.light,
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    greetingHeadingStyle: {
        fontSize:'20px',
        fontWeight:'600'
    },
    greetingTextStyle: {
        fontSize:'16px',
        marginTop:'16px',
        color:'#333'
    },
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "32px 48px",
        background: "#ffffff",
        height:'auto',
        // [theme.breakpoints.down("md")]: {
        //     padding: "45px 20px",
        //     }
    },
    onboardHeading: {
        fontSize : "19px",
        fontWeight: "600",
        margin:'0px auto 8px auto',
    },
    mainbg: {
        background: theme.palette.primary.light,
        height:'100vh',
        overflow:'auto'
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    imageStyle: {
        marginLeft:'16px',
        [theme.breakpoints.down(1400)] : {
            width:'75%'
        }
    },
    mainbg: {
        background: theme.palette.primary.light,
        height:'100vh'
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "180px",
        }
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing(2),
        color: 'white'
    },
    registerLink: {
        fontSize:'14px',
        color:'#202020',
        position:'relative',
        top:'8px',
        //textDecoration:'none',
        /* '&:hover': {
            textDecoration:'underline',
        } */
    },
    registerLink1: {
        fontSize:'14px',
        color:'#0000FF',
        position:'relative',
        left:'8px',
        textDecoration:'none',
        '&:hover': {
            textDecoration:'underline',
        }
    },
    bgImage: {
        backgroundColor: '#f7f7f7 !important'
    }
}));

const LoginPage = (props) => {
    //consoleToLog("Theme vikas: ", props);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const isMount = useIsMount();


        const classes = useStyles();
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [emailInValid, setEmailInValid] = useState(false);
        const [passwordInValid, setPasswordInValid] = useState(false);
        const [buttonLoading, setButtonLoading] = useState(false);
        
        const [open, setOpen] = useState(false);
        const [serviceName, setServiceName] = useState('');
        const [loading, setLoading] = useState(true);
        const [appTitleAndSubtitle, setAppTitleAndSubtitle] = useState(); 
        const [backUrl, setBackUrl] = useState('');
        const [queryParams, setQueryParams] = useState(getQueryParams(document.location.search));

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        useEffect(()=> {
            // trackGAPageView('/login');
            setLoading(true);
            document.title = 'eProcessify | Login';
            setServiceName(queryParams.service);
            setBackUrl(queryParams.back_url);
            const titleSubtitle = respectiveAppTitleAndSubtitle(queryParams.service);
            setAppTitleAndSubtitle(titleSubtitle);
        }, []);
        
        useEffect(() => {
                if(!isMount) {
                    if(serviceName !== '') {
                        const fetchToken = async () => {
                            try {
                            const data = await fetchAccessToken(serviceName);
                            if(data) {
                                consoleToLog('Response authenticateApi', data);
                                setLoading(false);
                                const {access_token, redirect_url, invalid_service, scope} = data;
                                const user_scope = scope?.join();
                                if(access_token) {
                                    if(redirect_url) {
                                        window.open(`${redirect_url}\?scope=${user_scope}`, '_self');
                                    } else if(!redirect_url && !invalid_service) {
                                        navigate(`/not-registered?service=${serviceName}`);
                                    } else if(!redirect_url && invalid_service) {
                                        navigate('/apps');
                                    }
                                } 

                            } 
                            } catch (error) {
                                consoleToLog("error fetchAccessToken", error);
                                setLoading(false);
                            }
                        };
                    
                        fetchToken();
                    } else {
                        navigate('/apps');
                        setLoading(false);
                    }
            }
        }, [serviceName])

    const onSubmitClick = () => {
        if (!email) {
            setEmailInValid(true);
            return;
        }
        const isEmailValid = emailValidator(email);
        if (!isEmailValid) {
            setEmailInValid(true);
            return;
        }

        if (!password) {
            setPasswordInValid(true);
            return;
        }
        let env = process.env.REACT_APP_DEBUG ? process.env.REACT_APP_ENV : undefined;
        setButtonLoading(true);
        signInApi(email, password, serviceName, env)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response signInApi: ", res);
                setButtonLoading(false);

                if(res.redirect_url) {
                    enqueueSnackbar('Login Successful', { 
                        variant: 'success',
                    });

                    props.setLoading(true);
                    const first_login = res?.user.first_login;
                    const scope = res.user.scope.join();
                    if(queryParams.back_url === 'activate' && queryParams.email) {
                        navigate(`/${queryParams.back_url}/?${document.location.search.replace(`?back_url=activate&`, '')}`)
                    } else {
                        //window.open(`https://localhost:3000/?service=${serviceName}&first_login=${first_login}&scope=${scope}${backUrl && backUrl !== '' ? `&back_url=${backUrl}` : ''}`, '_self');
                        window.open(`${res?.redirect_url}&first_login=${first_login}&scope=${scope}${scope}${backUrl && backUrl !== '' ? `&back_url=${backUrl}` : ''}`, '_self');
                    }
                    
                } else {
                    if(res.service) {
                        navigate(`/not-registered?service=${serviceName}`, {state: {access_token: res.access_token, user: res.user}})
                    } else {
                        navigate(`/apps`);
                    }
                } 
                
            })
            .catch((e) => {
                consoleToLog("Error signInApi: ", e);
                setButtonLoading(false)
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, { 
                        variant: 'error',
                    });
                    return false;
                }

                if(e.response.data && e.response.data.detail) {
                    enqueueSnackbar(e.response.data.detail, { 
                        variant: 'error',
                    });
                    return false;
                }
            });
    }

    const goToRegisterPageOrAllAppsPage = () => {
        if(serviceName === '') {
            return `/apps`
        } else {
            return serviceName ? `/register?service=${serviceName}` : `/register`;
        }
    }

    return (
            <div className={classes.mainContainer}>
                {loading ?
                <Grid item container justifyContent='center' alignItems='center' style={{marginTop:'18px'}}>
                    <CircularProgress size={28}/>
                </Grid>
                :
                <>
                <Grid item container>
                    <Grid item md={4} className={classes.onBoardingDesignStyle}>
                            <Grid item container direction='column'>
                                <Grid item md={12}>
                                    <img src='/images/eProcessify_logo_name_white.png' className={classes.logoStyle}/>
                                </Grid>

                                <Grid item md={12} className={classes.headingContainer}>
                                    <Typography className={classes.headingTextStyle}>
                                        {appTitleAndSubtitle?.title}
                                    </Typography>

                                    <Typography className={classes.paragraphTextStyle}>
                                        {appTitleAndSubtitle?.subtitle}
                                    </Typography>
                                </Grid>

                                <Grid item md={12} className={classes.imageContainer}>
                                    <img src='/images/on_borboarding_image.svg' className={classes.imageStyle}/>
                                </Grid>
                            </Grid>
                    </Grid>

                    <Grid item md={8} className={classes.rightPanelStyle}>
                        <Grid item md={7}>

                            <Grid item md={12} style={{marginTop:'32px'}}>
                            
                                <Grid item md={12} className={classes.innerbg}>
                                    <Typography className={classes.onboardHeading}>
                                        Log in to eProcessify
                                    </Typography>

                                    <Typography variant="subtitle1">Enter your credentials to access your account</Typography>

                                    <Grid container direction="column" >
                                        <Grid item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    type="text"
                                                    variant="outlined"
                                                    margin="dense"
                                                    error={emailInValid}
                                                    helperText={emailInValid ? "* Email is invalid!" : ''}
                                                    onChange={e => { 
                                                        setEmail(e.target.value);
                                                        setEmailInValid(false);
                                                    }}
                                                    InputLabelProps={{style: {fontSize: 14}}}
                                                />
                                            </Grid>
                                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                                            <TextField
                                                fullWidth
                                                id="standard-password-input"
                                                label="Password"
                                                type="password"
                                                margin="dense"
                                                variant="outlined"
                                                onKeyPress={(ev) => {
                                                    //consoleToLog(`Pressed keyCode ${ev.key}`);
                                                    if (ev.key === 'Enter') {
                                                        onSubmitClick();
                                                        ev.preventDefault();
                                                    }
                                                }}
                                                onChange={e => { 
                                                    setPassword(e.target.value);
                                                    setPasswordInValid(false);
                                                }}
                                                error={passwordInValid}
                                                helperText={passwordInValid ? "* Password is required!" : ''}
                                                InputLabelProps={{style: {fontSize: 14}}}
                                            />
                                        </Grid>
                                    </Grid>

                                        <Typography style={{marginTop: "12px", fontSize: "14px", cursor:'pointer'}} 
                                            color="secondary"
                                            onClick={handleClickOpen}
                                        >Forgot your password?
                                        </Typography>

                                    <Grid item md={12} style={{margin: '24px 0px 16px 0px', textAlign:'center'}}>  
                                        <Button variant="contained" color="primary" 
                                            fullWidth
                                            onClick={()=>onSubmitClick()}
                                            >
                                            {buttonLoading && <CircularProgress size={24} className={classes.circularProgress}/>}
                                            Login
                                        </Button>
                                        <div className={classes.registerLink}>
                                            Not registered?
                                            <Link to={goToRegisterPageOrAllAppsPage()} 
                                                className={classes.registerLink1}>
                                                Register
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* :
                <Grid item container justifyContent='center' alignItems='center'
                    style={{backgroundColor: '#f7f7f7 !important', height:'100%'}}
                    className={classes.bgImage}>
                    <img src='/images/404-not-found.png' />
                </Grid> */}
                </>
                }
                {<ForgotPasswordModal isOpen={open} handleClose={handleClose}/> }
            </div>
        )
}

const mapStateToProps = (state) => ({
    auth: state.auth.authObj
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);